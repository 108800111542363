.pageContainer {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 60px 15px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  table {
    color: #333;
    background: white;
    border: 1px solid grey;
    font-size: 12pt;
    border-collapse: collapse;
    margin-bottom: 25px;
  }
  table thead th,
  table tfoot th {
    color: #777;
    background: rgba(0, 0, 0, 0.1);
  }
  table caption {
    padding: 0.5em;
  }
  table th,
  table td {
    padding: 0.5em;
    border: 1px solid lightgrey;
  }
}

.loader {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 30px;
}
